<template>
  <div class="formdata" style="padding: 20px">
    <el-form ref="form" :model="form" label-width="180px">
      <el-form-item label="团队名称">
        <el-input
          v-model="form.deptCode"
          v-check-permission
          placeholder="请输入医生团队名称"
          disabled
          style="width: 30%"
        />
      </el-form-item>
      <el-form-item label="医生团队介绍：">
        <el-input
          v-model="form.disease"
          placeholder="请输入简介"
          disabled
          style="width: 30%"
          type="textarea"
          :rows="3"
        />
      </el-form-item>
      <el-form-item>
        <template>
          <el-button type="primary" @click="saveData()"> 保存 </el-button>
          <el-button type="primary" @click="$router.back()"> 返回 </el-button>
        </template>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
export default {
  name: 'Details',
  data() {
    return {
      isEditor: this.$router.query.type,
      signobj: {}, // 获取图片签名数据
      id: sessionStorage.id,
      form: {
        deptCode: '',
        deptName: '',
        logo: '',
        disease: '',
        state: '',
      },
    };
  },
  mounted() {
    this.getDetail();
    this.getImgSigns();
  },
  methods: {
    ...mapActions('institutionManage', ['addOrgan', 'editOrgan']),
    ...mapActions('upImage', ['getImgSign']),
    // 获取上传图片url
    getImgUrl(url) {
      this.newTaskData.orgImage = url;
    },
    // 获取图片上传签名
    getImgSigns() {
      this.getImgSign()
        .then((res) => {
          if (res.code === 0) {
            this.signobj = res.data;
          }
        })
        .catch((err) => {
          this.$message.error(err.message);
        });
    },
    // 返回
    goBack() {
      this.$router.go(-1);
      this.dialogVisible = false;
    },
    // 取消
    handleClose() {
      this.dialogVisible = false;
      this.$router.go(-1);
    },
    // 获取详情数据
    getDetail() {},
    // 新增和编辑时保存
    onSubmit() {
      this.editOrgan().then(() => {
        this.$message({
          type: 'success',
          message: '编辑成功',
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.formdata {
  label {
    font-size: 14px;
    color: #606266;
  }

  .input_el {
    width: 40%;
  }
}
// .timedemo span {
//   font-weight: 600;
//   color: red;
//   border: 2px solid red !important;
// }
</style>
